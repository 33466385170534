// 申遗承诺
import SHZT from './sn-table-column-shzt';

const selfData = {
    loading: false,
    snTable: {
        table: {
            data: [],
            rowKey: 'ID',
        },
        columns: {
            // 数据列
            columnsData: [
                {
                    prop: 'HCQMJ',
                    label: '缓冲区面积（公顷）',
                    minWidth: '',
                }, {
                    prop: 'YCQMJ',
                    label: '遗产区面积（公顷）',
                    minWidth: '',
                }, {
                    prop: 'WDSCSJ',
                    label: '上传时间',
                    minWidth: '',
                },
                SHZT
            ],
            // 操作列
            columnsBtn: {
                buttonData: []
            }
        },
    },
    snSearch: {
        inputData: [{
            label: '关键字',
            placeholder: "请输入关键字",
            value: 'keyword',
            operateType: 'search',
            isShow: true,
            default: ""
        }],
    },
    snButton: {
        buttonData: [{
            btnType: 'button',
            operateType: 'buttonExport',
            showMethod: 'showExportBtn',
            name: '导出',
            round: true,
            backColor: '#ffcc66',
            color: '#fff'
        }]
    }
};
export default selfData;